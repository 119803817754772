const env = {
  NETWORK_ID: 56,
  CHAIN_NAME: "bsc",
  URLS: {
    ETHERSCAN: 'https://bscscan.com/',
    API_SERVER: 'https://api.vesting.buni.finance'
  },
  NODE: {
    URL: "https://bsc-dataseed.binance.org",
  },
};

export default env;