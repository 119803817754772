import React from 'react';
import { useDispatch } from "react-redux";
import TrustWalletService from "src/app/services/accounts/TrustWalletService";
import ENV from "src/app/configs/env";
import { clearAccount, importAccount } from "src/app/actions/accountAction";
import { WALLET_TYPES } from "src/app/configs/constants";
import { fromNetworkIdToName, getWalletParams } from "src/app/utils/helpers";
import { modalService } from "src/app/components/commons/ModalListener";
import BasicModalContent from "src/app/components/commons/BasicModalContent";

export default function TrustWalletAccount(props: any) {
  const dispatch = useDispatch();

  async function connect() {
    const props = getWalletParams();
    const wallet = new TrustWalletService(props);
    
    if (!wallet.ethereum.isTrust) {
      return openWrongWalletErrorModal();
    }

    const address = await wallet.connect(openConnectErrorModal, openNetworkErrorModal);

    if (!address) return;


    wallet.getDisconnected(() => dispatch(clearAccount()));

    dispatch(importAccount(address, wallet, WALLET_TYPES.TRUST_WALLET));

    modalService.close();
  }

  function openConnectErrorModal() {
    modalService.show(BasicModalContent, {
      title: "Error",
      content: <div>Cannot connect to TrustWallet. Please make sure you have TrustWallet installed.</div>
    });
  }

  function openNetworkErrorModal(currentNetworkId: number) {
    modalService.show(BasicModalContent, {
      title: "Error",
      content: (
        <div className="text-center">
          <p className="mb-2">TrustWallet should be on <b>{fromNetworkIdToName(ENV.NETWORK_ID)}</b>.</p>
          <p>Currently it is on <b>{fromNetworkIdToName(currentNetworkId)}</b> instead.</p>
        </div>
      )
    });
  }

  function openWrongWalletErrorModal() {
    modalService.show(BasicModalContent, {
      title: "Error",
      content: <div>This wallet is not TrustWallet. Please connect via TrustWallet in-app browser.</div>
    });
  }

  return (
    <div className={`account__item ${props.className}`} onClick={connect}>
      <div className="account__icon trustwallet"/>
      <div className="account__name">TrustWallet</div>
    </div>
  )
};
