const env = {
  NETWORK_ID: 3,
  CHAIN_NAME: "Ropsten Test Network",
  URLS: {
    ETHERSCAN: "https://ropsten.etherscan.io/",
    API_SERVER: "http://api.token-distributor.sotatek.works",
    // API_SERVER: "http://localhost:6886",
  },
  NODE: {
    URL: "https://ropsten.infura.io/v3/161bb74e55ff44ef8fe6fc7c16ee1692",
  },
};

export default env;
